const Survey = () => import('../components/Survey.vue');
const Results = () => import('../components/Results.vue');
const FourOhFour = () => import('../components/FourOhFour.vue');


export const routes = [{
		path: '*',
		redirect: '/'
	},{ 
		path: '/', 
		name: "survey",
		component: Survey,
		meta: {
		// requiresAuth: true
		}
	},{ 
		name: "results",
		path: '/results/:id', 
		component: Results,
		meta: {
		// requiresAuth: true
		} 
	},{ 
		name: "fourOhFour",
		path: '/404', 
		component: FourOhFour,
		meta: {
		// requiresAuth: true
		}
	}]


